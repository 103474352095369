
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat-typography-config(
  $font-family: 'PT Sans'
);
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


// Custom Color Primary
$aa-blue: (
    50: #0066CC,
    100: #0066CC,
    200: #0066CC,
    300: #0066CC,
    400: #0066CC,
    500: #0066CC,
    600: #0066CC,
    700: #0066CC,
    800: #0066CC,
    900: #0066CC,
    A100: #0066CC,
    A200: #0066CC,
    A400: #0066CC,
    A700: #0066CC,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);

// Custom Color Secondary
$aa-cyan: (
    50: #00AEEF,
    100: #00AEEF,
    200: #00AEEF,
    300: #00AEEF,
    400: #00AEEF,
    500: #00AEEF,
    600: #00AEEF,
    700: #00AEEF,
    800: #00AEEF,
    900: #00AEEF,
    A100: #00AEEF,
    A200: #00AEEF,
    A400: #00AEEF,
    A700: #00AEEF,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);


$agendamiento-peru-primary: mat-palette($aa-blue);
$agendamiento-peru-accent: mat-palette($aa-cyan);

// The warn palette is optional (defaults to red).
$agendamiento-peru-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$agendamiento-peru-theme: mat-light-theme((
  color: (
    primary: $agendamiento-peru-primary,
    accent: $agendamiento-peru-accent,
    warn: $agendamiento-peru-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($agendamiento-peru-theme);


*, *::before, *::after {
    box-sizing: border-box;
}

::ng-deep .mat-tooltip  {
  white-space: pre-line !important;
}

.tooltip-class{
  white-space: pre-line !important;
}

html {
  font-family: 'PT Sans', sans-serif!important;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Work Sans', sans-serif!important;
  font-weight: normal !important;
}

body {
  margin: 2em;
  bottom: 50px;
  background-color: rgba(255, 255, 255, 0.85);
  height: 100%;
}

.main-content {
  height: inherit;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 0 4px rgb(0 0 0 / 30%);
}

hr {
  opacity: 0.1;
}

body {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  padding-bottom: 60px;
}

.mat-snack-bar-container {
  opacity: 1 !important;
  color: #fff !important;
  background: #535F6A !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}



.mat-simple-snackbar-action {
  color: #ffffff!important;
}
.mat-simple-snack-bar-content{
  color: #000 !important;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  line-height: 134.3%;
  font-weight: 500;
}
.mat-simple-snackbar-action button{
  color: #000 !important;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}
.mat-snack-bar-container{
  border-radius: 17px !important;
  padding: 20px 20px !important;
}

.mat-snack-bar-container {
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 24px;
  max-width: 33vw;
  min-width: 300px !important;
  padding: 14px 16px;
  min-height: 48px;
  transform-origin: center;
}

/*
.mat-datepicker-content .mat-calendar {
  width: 208px !important;
  height: 250px !important;
}*/

.mat-calendar-header {
  padding: 0px 8px 0 8px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-promocion .img-fluid {
  max-width: 600px;
  /* width: 100%; */
}

html, body { height: 100%; }
body { margin: 0; font-family: 'PT Sans', sans-serif;}


.material-content{
  padding-top:50px;
}


.mat-button{
  border-radius: 16px!important;
}



.orange-snackbar{
  background-color: #fff!important;
  font-weight: bold !important;
}

.andesmotor-style mat-card{
  background-color: rgba(255,255,255,1)!important;
}

/*Dialog*/
.mat-dialog-actions{
  margin-bottom:0px!important;
}

@media (max-width: 767px) {


  .mat-horizontal-content-container {
      padding: 0 1px 24px 1px!important;
  }
  .mat-expansion-panel-header{
    height: 110px !important;
  }
  .cdk-overlay-pane{
    max-width: 100vw !important;
  }
  .divemotor-style .style-table .mat-expansion-indicator::after{
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }
}

/*Boton Ver Detalle*/
.andesmotor-style .style-table .mat-expansion-indicator::after {
  content: "Ver Detalle" !important;
  transform: rotate(0deg) !important;
  background: #012169;
  width: 100px !important;
  color: #fff;
  padding: 10px 13px !important;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  margin-top: 41px;
  animation: normal;
}


/*Boton cerrar detalle (flecha)*/
.andesmotor-style .style-table .mat-expanded .mat-expansion-indicator::after {
  content: ""!important;
  transform: rotate(45deg)!important;
  background: transparent;
  width: auto;
  height: auto;
  color: #000;
  vertical-align: middle;
  border-radius: 0;
  width: 0 !important;
  padding: 4px 4px!important;
}

.andesmotor-style .recall-button, .andesmotor-style .search-button{
  background: #012169 !important ;
  color: #fff;
}
.divemotor-style .recall-button, .divemotor-style .search-button{
  background: #0066CC !important; 
  color: #fff;
}
.andesmotor-style .sk-fading-circle{
  color: #012169 !important ;
}

.divemotor-style .style-table .mat-expansion-indicator::after {
  content: "Ver Detalle" !important;
  transform: rotate(0deg) !important;
  background: #0066CC;
  width: 100px !important;
  color: #fff;
  padding: 10px 13px !important;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  margin-top: 41px;
  animation: normal;
}


/*Boton cerrar detalle (flecha)*/
.divemotor-style .style-table .mat-expanded .mat-expansion-indicator::after {
  content: ""!important;
  transform: rotate(45deg)!important;
  background: transparent;
  width: auto;
  height: auto;
  color: #000;
  vertical-align: middle;
  border-radius: 0;
  width: 0 !important;
  padding: 4px 4px!important;

}

